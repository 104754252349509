<template>
  <div class="h-auto bg-white flex font-poppins">
    <div class="flex-1 flex flex-col md:py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img class="h-12 w-auto" :src="require(`@/assets/img/durban.jpg`)" alt="Workflow" />
          <h2 class="mt-6 text-3xl text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div class="mt-4 md:mt-8">

          <div class="md:mt-6">
            <form @submit.prevent="submitForm" class="space-y-6">
              <div>
                <label for="username" class="block text-sm font-medium text-gray-700">
                  Username
                </label>
                <div class="mt-1">
                  <input id="username" name="username" type="text" autocomplete="username" v-model="username" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
              </div>

              <div class="space-y-1">
                <label for="password" class="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div class="mt-1">
                  <input id="password" name="password" type="password" autocomplete="current-password" v-model="password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
              </div>

              <div class="flex items-center justify-between">

                <div class="text-sm">
                  <a href="#" class="font-medium text-coral hover:text-indigo-500">
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div class="text-red-700" v-if="errors.length">
                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
              </div>

              <div>
                <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-coral hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Sign in
                </button>
              </div>
            </form>

            <div class="mt-6">
              <div class="relative">
                <div class="absolute inset-0 flex items-center">
                  <div class="w-full border-t border-gray-300" />
                </div>
                <div class="relative flex justify-center text-sm">
              <span class="px-2 bg-white text-gray-500">
                Or <router-link class="underline text-blue-900" to="/signup">click here</router-link> to sign up.
              </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img class="absolute inset-0 h-full w-full object-cover" :src="require(`@/assets/img/signin.jpg`)" alt="" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "login",
  data() {
    return {
      username: '',
      password: '',
      errors: []
    }
  },
  mounted() {
    document.title = 'Log In | Durban'
  },
  methods: {
    async submitForm() {
      this.$store.commit('setIsLoading', true)
      const formData = {
        username: this.username,
        password: this.password
      }
      await axios
          .post("/api/v1/token/login/", formData)
          .then(async response => {
            const token = response.data.auth_token
            await this.$store.commit('setToken', token)

            axios.defaults.headers.common.Authorization = "Token " + token
            await localStorage.setItem("token", token)
            const toPath = this.$route.query.to || '/myprofile'
            await this.$store.commit('login')
            await this.$router.push(toPath)
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            } else {
              this.errors.push('Something went wrong. Please try again')

              console.log(JSON.stringify(error))
            }
          })
      this.$store.commit('setIsLoading', false)
    }
  }
}
</script>

<style scoped>

</style>
